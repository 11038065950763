import React, { useState, useEffect } from 'react';
import POSComponent from './components/POSComponent';
import api from './apiConfig';
import appCss from './App.css'
import { Button, TextField, Dialog, DialogTitle, DialogContent, DialogActions, Snackbar, InputAdornment, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const App = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [authorized, setAuthorized] = useState(false);
  const [openDialog, setOpenDialog] = useState(true);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const token = localStorage.getItem('access');

  const fetchFilters = async () => {
    try {
      if (!token) {
        console.error('Token não encontrado!');
        alert('Token ausente ou inválido!');
        return;
      }

      // console.log("Token recuperado:", token);

      const filtersResponse = await api.get('/api/filters/', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // console.log("Esse é o meu filtersResponse:", filtersResponse);

      const filters = filtersResponse.data.map(filter => ({
        id: filter.id,
        name: filter.name,
        order: filter.order,
        categories: filter.categories.map(category => ({
          id: category.id,
          name: category.name,
          order: category.order,
          products: category.products.sort((a, b) => {
            // Ordenar os produtos primeiro por is_promotional, depois por is_trending
            if (a.is_promotional !== b.is_promotional) {
              return a.is_promotional ? -1 : 1;
            } else if (a.is_trending !== b.is_trending) {
              return a.is_trending ? -1 : 1;
            } else {
              return a.order - b.order; // Se ambos is_promotional e is_trending forem iguais, ordene por order
            }
          }).map(product => ({
            id: product.id,
            name: product.name,
            group: product.group,
            desconto_maximo: product.desconto_maximo,
            valor_maximo: product.valor_maximo,
            image_path: product.image_path,
            order: product.order,
            price10x: product.price10x,
            price6x: product.price6x,
            price_pix: product.price_pix,
            description: product.description,
            is_promotional: product.is_promotional,
            is_trending: product.is_trending
          }))
        }))
          .sort((a, b) => a.order - b.order)
      }))
        .sort((a, b) => a.order - b.order);
      setData({ filters });
      setLoading(false);
    } catch (error) {
      console.error('Erro ao buscar filtros:', error);
      setLoading(false);
    }
  };

  const refreshAccessToken = async (refreshToken) => {
    try {
      const response = await fetch('https://orcamentodigital.app/api/token/refresh/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ refresh: refreshToken }),
      });
      const data = await response.json();
      if (response.ok) {
        localStorage.setItem('access', data.access); // Atualiza o token de acesso no localStorage
      } else {
        console.error('Erro ao atualizar o token de acesso:', data);
      }
    } catch (error) {
      console.error('Erro ao atualizar o token de acesso:', error);
    }
  };

  const isTokenNearExpiry = (token) => {
    if (!token) return false;
    const tokenPayload = JSON.parse(atob(token.split('.')[1]));
    const expiryTime = tokenPayload.exp * 1000; // O tempo de expiração está em segundos
    const currentTime = Date.now();
    const bufferTime = 5 * 60 * 1000; // Exemplo: renovar se estiver a 5 minutos de expirar
    return currentTime + bufferTime > expiryTime;
  };


  const handleLogin = async () => {
    const credentials = {
      username: username,
      password: password,
    };

    try {
      const response = await fetch('https://orcamentodigital.app/api/login/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(credentials),
      });

      if (response.ok) {
        const data = await response.json();
        localStorage.setItem('access', data.access);  // Armazenar o token JWT
        localStorage.setItem('refresh', data.refresh);
        setAuthorized(true);  // Define o estado como autorizado
        setSnackbarMessage('Login bem-sucedido!');
        setSnackbarOpen(true);
        handleCloseDialog();

        await fetchFilters();  // Chama a função para buscar os filtros
      } else {
        setSnackbarMessage('Falha no login. Verifique suas credenciais.');
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarMessage('Erro ao tentar fazer login.');
      setSnackbarOpen(true);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const accessToken = localStorage.getItem('access');
      const refreshToken = localStorage.getItem('refresh');
  
      if (isTokenNearExpiry(accessToken)) {
        refreshAccessToken(refreshToken); // Atualiza o token de acesso automaticamente
      }
    }, 4 * 60 * 1000); // Verifica a cada 4 minutos
  
    return () => clearInterval(interval); // Limpa o intervalo quando o componente é desmontado
  }, []);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleLogin();
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  if (!authorized) {
    return (
      <>
        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <DialogTitle>Digite suas credenciais para acessar o Sistema POS - Não + Pelo</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="Usuário"
              type="text"
              fullWidth
              value={username}
              onChange={handleUsernameChange}
            />
            <TextField
              margin="dense"
              label="Senha"
              type={showPassword ? 'text' : 'password'} // Alterar o tipo conforme o estado
              fullWidth
              value={password}
              onChange={handlePasswordChange}
              onKeyPress={handleKeyPress}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={togglePasswordVisibility} edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />} {/* Ícone para alternar a visibilidade */}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              Cancelar
            </Button>
            <Button onClick={handleLogin} color="primary">
              Enviar
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          message={snackbarMessage}
        />
      </>
    );
  }

  if (loading) {
    return (
      <div className="loading-container">
        <div className="spinner"></div>
      </div>
    );
  }

  return (
    <div className="App">
      <POSComponent data={data} username={username} password={password} token={token} />
    </div>
  );
};

export default App;
