import React, { useState } from 'react';
import * as XLSX from 'xlsx';

const handleFileUpload = async (file, setData) => {
    const reader = new FileReader();

    reader.onload = async (event) => {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: 'array' });

        // Obtém a primeira planilha
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];

        // Converte a planilha para um formato JSON
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        // Formata os dados para a API
        const formattedData = jsonData.slice(1).map((row) => {
            // Verifica se a linha está completamente vazia
            if (row.every(cell => cell === null || cell === '')) {
                return null; // Retorna null para linhas vazias
            }
            return {
                name: row[3] || '',
                category: row[2] || '',
                gender: row[1] || '', // Gender como string
                group: row[0] || '', // Default para string vazia se undefined
                valor_maximo: parseFloat(row[4]) || 0, // Default para 0 se NaN
                desconto_maximo: parseFloat(row[5]) || 0,
                price_pix: parseFloat(row[6]) || 0,
                price6x: parseFloat(row[7]) || 0,
                price10x: parseFloat(row[8]) || 0,
            };
        }).filter(row => row !== null); // Remove linhas que retornaram null

        // Atualiza o estado com os dados formatados
        setData(formattedData);
    };

    reader.readAsArrayBuffer(file);
};

// Função para enviar os dados à API
// Função para enviar os dados à API
const sendDataToAPI = async (data, filters) => {
    const token = localStorage.getItem('access');
    try {
        for (const item of data) {
            // Substitui os gêneros e categorias pelos seus respectivos ids
            const matchingFilter = filters.find(filter => filter.name.toLowerCase() === item.gender.toLowerCase());

            if (!matchingFilter) {
                console.warn(`Gênero não encontrado para o item: ${item.name}`);
                continue; // Ignora este item se não encontrar o filtro correspondente
            }

            const matchingCategory = matchingFilter.categories.find(
                category => category.name.toLowerCase() === item.category.toLowerCase()
            );

            if (!matchingCategory) {
                console.warn(`Categoria não encontrada para o item: ${item.name}`);
                continue; // Ignora este item se não encontrar a categoria correspondente
            }

            const itemWithIds = {
                ...item,
                gender: matchingFilter.id, // Substitui o gênero pelo id do filtro
                category: matchingCategory.id, // Substitui a categoria pelo id da categoria
            };

            console.log(itemWithIds)

            // Enviar item formatado para a API
            const response = await fetch('https://orcamentodigital.app/api/products/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(itemWithIds),
            });

            if (!response.ok) {
                throw new Error(`Erro ao enviar o item: ${item.name}`);
            }

            const result = await response.json();
            console.log(`Dados enviados com sucesso para o item: ${item.name}`, result);
        }
    } catch (error) {
        console.error('Erro ao enviar dados:', error);
    }
};

// Componente de upload de arquivo
const FileUpload = ({ setData }) => {
    const handleChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            handleFileUpload(file, setData);
        }
    };

    return (
        <input
            type="file"
            accept=".xlsx, .xls"
            onChange={handleChange}
        />
    );
};

// Componente para exibir os dados da planilha
const DataTable = ({ data }) => {
    if (!data.length) return null;

    return (
        <table>
            <thead>
                <tr>
                    <th>Grupo</th>
                    <th>Gênero</th>
                    <th>Categoria</th>
                    <th>Nome</th>
                    <th>Valor Máximo</th>
                    <th>Desconto Máximo</th>
                    <th>Preço PIX</th>
                    <th>Preço 6x</th>
                    <th>Preço 10x</th>
                </tr>
            </thead>
            <tbody>
                {data.map((row, index) => (
                    <tr key={index}>
                        <td>{row.group}</td>
                        <td>{row.gender}</td>
                        <td>{row.category}</td>
                        <td>{row.name}</td>
                        <td>{row.valor_maximo}</td>
                        <td>{row.desconto_maximo}</td>
                        <td>{row.price_pix}</td>
                        <td>{row.price6x}</td>
                        <td>{row.price10x}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

// Componente para exibir dados em formato JSON
const JsonDisplay = ({ data }) => {
    if (!data.length) return null;

    return (
        <pre>
            {JSON.stringify(data, null, 2)}
        </pre>
    );
};

const Sheets = ({ filters }) => {
    const [data, setData] = useState([]);
    const [showJson, setShowJson] = useState(false);

    const handleShowJson = () => {
        setShowJson(true);
    };

    const handleSendToAPI = async () => {
        if (data.length) {
            await sendDataToAPI(data, filters);
        } else {
            console.log('Nenhum dado para enviar');
        }
    };

    return (
        <div>
            <h1>Upload de Planilha</h1>
            <FileUpload setData={setData} />
            <button onClick={handleShowJson}>Mostrar JSON</button>
            <h2>Quantidade de produtos: {data.length}</h2>
            {showJson && <JsonDisplay data={data} />}
            <DataTable data={data} />
            {/* Botão para enviar dados para a API */}
            <button onClick={handleSendToAPI}>Enviar para API</button>
        </div>
    );
};

export default Sheets;
